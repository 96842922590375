import TableHeader from '../Tables/TableHeader';
import TableRow from '../Tables/TableRow';
import {Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import Context from '../Context';
import {useContext, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';

const Table = ({text, data, setAssign, setId, stuff, enquiries, open, setData, dataBackUp, setDataBackUp, customers, setSoloCustomer, setSoloJobDetails, setSpinner, spinner, pageLinks, pageNo, setPageNo, setDataTsk}) => {

  const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId, sign, setSign] = useContext(Context);

  const changeStatus = async (thisId) =>
  {
    //setSpinner(true);
      const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/${thisId}/action`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({'tech': 'none'}),
      });



      if (res.ok) {
        //throw new Error('Network response was not ok');

        toast.success('Task was updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/paginateWeb?main=${main}`);
        const data = await res.json();
        setDataTsk(data.data);
      }
      else
      {
        toast.error('Action failed please check network connection!!!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      }
  }

  const seachFocus = () =>
  {
    const seach = document.getElementById('seach');
    //seach.style.border = "1px solid green";
  }

  const showHideFilters = () =>
  {
    const filters = document.getElementById('filters');

    if(filters.style.display === "none")
      filters.style.display = "block";
    else
      filters.style.display = "none";
  }

  const filterByStatus = (value) => {
      setData(value !== "All"?dataBackUp.filter((job) => (job.Status === value) ):dataBackUp);
  }

  const filterByJobType = (value) => {
      setData(value !== "All"?dataBackUp.filter((job) => (job.Task_Type === value) ):dataBackUp);
  }

  const search = (value) =>
  {
    //console.log(dataBackUp);
      setData(value !== ""?dataBackUp.filter((job) => (job.Customer.toLowerCase().includes(value.toLowerCase()) || job.Assigned_to.toLowerCase().includes(value.toLowerCase()) || job.Assigned_by.toLowerCase().includes(value.toLowerCase())) ):dataBackUp);
  }

  const seachSuff = (value) =>
  {
    setData(dataBackUp.filter((job) => (job.Name.toLowerCase().includes(value.toLowerCase()))  ||  (job.Last_Name.toLowerCase().includes(value.toLowerCase()))  ||  (job.Phone_Number.toLowerCase().includes(value.toLowerCase()))  ||  (job.Email.toLowerCase().includes(value.toLowerCase())) ));
  }

  const getStuff = async() => 
  {
    const res = await fetch('https://api.te-amo.co.za/public/api/stuff');
    const data = await res.json();

    //console.log(data);
    //setStuff(data);
  }

  const paginate = async(e, f) => {
    const res = await fetch(`${e}&main=${main}`);
    const data = await res.json();

    setData(data.data);
    setDataBackUp(data.data);
    setPageNo(parseInt(f));
    window.scrollTo(0, 0);
  }

	return (

		<div className="tableCard2">
      <div className="row">
        <div className="col-9">
          <div className="row">
            <div className="col-md-3">
                <input type="text" placeholder="Search" id="seach" className="myFormInput" onFocus={seachFocus} onChange={stuff||customers?(e)=>seachSuff(e.target.value):(e)=>search(e.target.value)} />
            </div><br /><br />
            {!stuff && !customers && !enquiries?
              <div className="col-md-9">
                  <button className="btn-filter" onClick={showHideFilters}>Filter</button>
              </div>
              :''
            }
          </div>
        </div>

        <div className="col-3">
            <button className="btn-filter">Export</button>
            {stuff?<button className="btn-filter mx-3" onClick={() => open(false)}>add</button>: ''}
        </div>
      </div><br />


      {!stuff && !customers && !enquiries?
      <div id="filters">
        <div className="row">
            <div className="col-md-4">
              <select placeholder="Job status" className="myFormInput" onChange={(e) => filterByStatus(e.target.value)}>
                  <option disabled selected hidden>Job status</option>
                  <option value="All">All</option>
                  <option value="Pending">Booked in</option>
                  <option value="Active">Active</option>
                  <option value="Completed">Completed</option>
              </select><br /><br />
            </div>

            <div className="col-md-4">
              <select placeholder="Job status" className="myFormInput" onChange={(e) => filterByJobType(e.target.value)}>
                  <option disabled selected hidden>Job type</option>
                  <option value="All">All</option>
                  <option value="Installation">Installation</option>
                  <option value="Assessment">Assessment</option>
                  <option value="Call out">Call out</option>
                  {
                    stuff?.map((member) => <option value={member.Name}>{member.Name}</option>)
                  }
              </select>
            </div>

            <div className="col-md-4">

            </div>
        </div>
      </div>
      :''
      }
			<table className="table table-striped mt-2">
                <TableHeader stuff={stuff} customers={customers} enquiries={enquiries} />

                <tbody>
                  {
                    (stuff || customers)?data?.map((task) => 
                      <TableRow ThisName={task.Name} lastName={task.Last_Name} phoneNumber={task.Phone_Number} thisEmail={task.Email} stuff={stuff} customers={customers} />
                    )
                    :(enquiries?data?.map((task) => (
                       <TableRow ReadOrNot={task.ReadOrNot} enquiries={enquiries} thisId={task.id} company={task.company} nameEnquiries={task.name} lastname={task.lastname} priority={task.priority} phone={task.phone} emailEnquiry={task.email} type={task.type} message={task.message} />
                      )):
                    data?.map((task) => (task.Status==="Active"?
                        <TableRow thisId={task.id} entry="1" title={task.Task_Name} Description_of_job={task.Description_of_job} Intallation_type={task.Intallation_type} category={task.Customer} status={task.Status} AssignedTo={task.Assigned_to} AssignedBy={task.Assigned_by} customerNumber={task.Customer_number} action={(task.Status==="Pending")?"Start" : "Complete"} changeStatus={changeStatus} activeClass={true} completeClass={false} setAssign={setAssign} setId={setId} updated_at={task.updated_at.slice(0, 10)} created_at={task.created_at.slice(0, 10)} setSoloCustomer={setSoloCustomer} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} />: (task.Status==="Completed" 
                          ? <TableRow thisId={task.id} entry="1" title={task.Task_Name} Description_of_job={task.Description_of_job} Intallation_type={task.Intallation_type} category={task.Customer} status={task.Status} AssignedTo={task.Assigned_to} AssignedBy={task.Assigned_by} customerNumber={task.Customer_number} action={(task.Status==="Pending")?"Start" : "Complete"} changeStatus={changeStatus} activeClass={false} completeClass={true} setAssign={setAssign} setId={setId} updated_at={task.updated_at.slice(0, 10)} created_at={task.created_at.slice(0, 10)} setSoloCustomer={setSoloCustomer} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} /> :
                        <TableRow thisId={task.id} entry="1" title={task.Task_Name} Description_of_job={task.Description_of_job} Intallation_type={task.Intallation_type} category={task.Customer} status={task.Status} AssignedTo={task.Assigned_to} AssignedBy={task.Assigned_by} customerNumber={task.Customer_number} action={(task.Status==="Pending")?"Start" : "Complete"} changeStatus={changeStatus} activeClass={false} completeClass={false} setAssign={setAssign} setId={setId} updated_at={task.updated_at.slice(0, 10)} created_at={task.created_at.slice(0, 10)} setSoloCustomer={setSoloCustomer} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} />)
                        )
                    ))
                  }
                </tbody>
              </table> <br /><br />


              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {
                    pageLinks?.map((e) => (
                      <li className={pageNo===parseInt(e.label)?`page-item active`:`page-item`} onClick={()=>paginate(e.url, e.label)}><Link class="page-link" to="#">{e.label}</Link></li>
                    ))
                  }
                </ul>
              </nav>

		</div>

	);
}

export default Table